<template>
  <v-container>
    <div class="document">
      <div class="document-form">
        <v-form ref="form">
          <v-row class="mt-10 mb-16 ms-4">
            <v-col cols="12" md="6" lg="6">
              <div class="choose-image1">
                <div class="show-image1">
                  <i class="fas fa-file-pdf"></i>
                  <input
                    @change="PreviewPDF"
                    type="file"
                    class="input-file-image1"
                  />
                  <p v-if="!uploadSuccess">
                    {{ $t("document.document File") }}
                  </p>
                  <p v-else>
                    ອັບໂຫລດສຳເລັດ
                    <span
                      ><i
                        style="color: #6CB669 !important;font-size: 22px"
                        class="fas fa-check-circle"
                      ></i
                    ></span>
                  </p>
                  <embed
                    class="embed-pdf1"
                    v-if="showPDF"
                    :src="showPDF"
                    type="application/pdf"
                  />
                  <v-progress-circular
                    v-if="showUpload"
                    style="z-index: 999999999999"
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-col cols="12" md="12" lg="12">
                <v-text-field
                  :label="$t('document.document number')"
                  outlined
                  v-model="item.document_number"
                  :hint="`${server_errors.document_number}`"
                  persistent-hint
                  dense
                >
                </v-text-field>
              </v-col>
              <!--            <v-col cols="12" md="12" lg="12" v-if="this.listDocument <= 0">-->
              <!--              <v-select-->
              <!--                  :label="$t('document.select user')"-->
              <!--                  outlined-->
              <!--                  v-model="item.authorizedUsers"-->
              <!--                  :items="listUser"-->
              <!--                  :hint="server_errors.authorizedUsers"-->
              <!--                  persistent-hint-->
              <!--                  item-text="userName"-->
              <!--                  item-value="userId"-->
              <!--                  multiple-->
              <!--                  dense>-->
              <!--              </v-select>-->
              <!--            </v-col>-->
              <v-col cols="12" md="12" lg="12" class="mt-4">
                <v-text-field
                  :label="$t('document.type')"
                  outlined
                  :items="documentType"
                  item-text="name"
                  item-value="name"
                  v-model="this.type"
                  :hint="server_errors.type"
                  persistent-hint
                  readonly
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="12" class="mt-4">
                <DatePicker
                  v-model="item.year"
                  style="width: 100%; !important;"
                  type="year"
                  :placeholder="$t('document.year')"
                >
                </DatePicker>
                <label class="error-validate ml-3" style="font-size: 12px">{{
                  server_errors.year
                }}</label>
              </v-col>
              <v-col cols="12" md="12" lg="12" class="mt-7">
                <v-btn
                  class="btn-save-change"
                  @click="ValidateForm"
                  :loading="btnLoading"
                >
                  {{ $t("document.save") }}
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      showPDF: "",
      imagePDF: "",
      listUser: [],
      listDocument: [],
      type: this.$route.query.type,
      btnLoading: false,
      checkStatus: true,
      item: {
        document_number: "",
        year: "",
        // authorizedUsers: "",
        type: "",
        file: "",
      },
      server_errors: {
        document_number: "",
        year: "",
        // authorizedUsers: "",
        type: "",
        file: "",
      },
      documentType: [
        {
          name: "in",
        },
        {
          name: "out",
        },
      ],
      showUpload: false,
      uploadSuccess: false,
    };
  },
  methods: {
    PreviewPDF: function(event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imagePDF = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showPDF = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.showUpload = true;
        this.UploadPDF(this.imagePDF);
      }
    },
    UploadPDF(imagePDF) {
      let formData = new FormData();
      formData.append("imageFile", imagePDF);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.uploadSuccess = true;
          this.showUpload = false;
          this.item.file = res.data.filename;
        }
      });
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    // fetchUser() {
    //   this.$axios.get(`company/list-user`).then((res) => {
    //     if (res.status === 200) {
    //       this.listUser = res.data.data.data;
    //       console.log(this.listUser)
    //     }
    //   })
    // },
    fetchLengthOfDocument() {
      this.$axios
        .get(`company/list/documents/${this.type}`, {
          params: {
            year: "all",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listDocument = res.data.data.length;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    saveChange() {
      if (this.listDocument <= 0) {
        this.checkStatus = false;
      }
      const documentItem = {
        file: this.item.file,
        document_number: this.item.document_number,
        year: moment(this.item.year).format("YYYY"),
        type: this.type,
        check: this.checkStatus,
        // authorizedUsers: this.item.authorizedUsers
      };
      console.log(documentItem);
      this.$axios
        .post(`company/add/document`, documentItem)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.$router.push({
            name: "document.table",
            query: {
              type: this.type,
            },
          });
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.message;
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              9000,
              (this.server_errors = obj)
            );
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    this.fetchLengthOfDocument();
    // this.fetchUser();
  },
};
</script>

<style scoped lang="scss">
// .document {
//   width: 100%;
//   height: 600px;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .document-form {
//     width: 500px;
//     height: 600px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }

.choose-image1 {
  width: 500px;
  height: 360px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .show-image1 {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 2px solid #eeeeee;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    font-family: $font-family;

    i {
      font-size: 4rem;
      color: #1976d2;
    }

    .input-file-image1 {
      position: absolute;
      bottom: 100px;
      height: 100%;
      left: 0;
      opacity: 0;
      outline: none;
      top: 0;
      width: 100%;
      cursor: pointer;
      background-color: red;
      z-index: 9;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
  }

  .embed-pdf1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
    overflow-x: hidden;
  }

  .show-remove-image1 {
    position: absolute;
    font-size: 1.5rem;
    color: red;
    top: 5px;
    right: 10px;
    z-index: 10;
    cursor: pointer;
  }
}
</style>
